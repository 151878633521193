.App {
 
  overflow-x: hidden;
}
 



.Footer_description{
  text-align: justify;
}
.menu_footer{
  text-decoration: none;
  font-family:Georgia, 'Times New Roman', Times, serif
}

.form-group{
  font-family:Georgia, 'Times New Roman', Times, serif
}

.footer_address{
  font-family:Georgia, 'Times New Roman', Times, serif
}

.footer_heading{
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font:bold;
  text-indent:5px;
}




/* SCSS HERE START */



@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);



.line-1{
    position: relative;
    top: 50%;  
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%) infinite;    
}


.anim-typewriter{
  animation: typewriter 4s steps(44) 1s 1 normal both,
             blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
  from{width: 0;}
  to{width: 24em;}
}
@keyframes blinkTextCursor{
  from{border-right-color: rgba(255,255,255,.75);}
  to{border-right-color: transparent;}
}










.Product_List{
  text-align: justify;
}

.panel-body{
  text-align: justify;
}


