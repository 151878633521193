@import url('https://fonts.googleapis.com/css?family=Quicksand');

html,body {
  height: 100%;
}

.wrapper {
  height: 100%;
  font-family: 'Quicksand', sans-serif;
  line-height: 1.5;
  word-spacing: 4px;
  letter-spacing: 1px;
}

.fixed-bg {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%; 
  width: 100%;
  color: rgb(243, 11, 108);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  display: table;
  
}

.fixed-bg h1 {
  display: table-cell;
  vertical-align: middle;
  padding:200px 500px;
}

.scroll-bg {
  background-color: #48b0ca;
  padding: 10px 70px;
  color: #0c0c0c;
  text-align: justify;
}
.section-title{
  margin-top: 7rem;
  margin-bottom: 2rem;
  font-size: 50px;
  font-weight: 600;
}

/* first section */

.bg-1 {
  background-image: url(../images/R1.jpg);
}



.bg-2 {
  background-image: url(../images/R2.jpg);
}



.bg-3 {
  background-image: url(../images/Back3.webp);
}

.bg-4 {
  background-image: url(../images/R4.webp);
}



.bg-5 {
  background-image: url(../images/R5.jpg);
}



.bg-6 {
  background-image: url(../images/R6.jpg);
}



.text-high{
  font-size: 40px;
  font-weight: 700;
  color:rgba(239, 44, 144, 0.91)
}

.my_text {
  text-align: justify;
  font-size: 20px;
  font-weight: 400;
}



/* ML.NET CSS START FROM HERE */

@import url("https://fonts.googleapis.com/css?family=Poppins|Ubuntu&display=swap");

.box {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.ML_Heading{
  font-size: 40px;
  font-weight: 700;
  color: rgba(239, 44, 144, 0.91);
}
.Ml_text{
  text-align: justify;
}

/* .Ml_text:hover {
  color: #fff;
} */
.our-services {
  margin-top: 75px;
  padding-bottom: 30px;
  padding: 0 60px;
  min-height: 198px;
  text-align: center;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 25px 0 rgba(20, 27, 202, 0.17);
}
.our-services .icon {
  margin-bottom: -21px;
  transform: translateY(-50%);
  text-align: center;
}
.our-services:hover h4,.our-services:hover h6,
.our-services:hover p {
  color: #fff;
}
.speedup:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #fb0054 0%, #f55b2a 100%);
}
.settings:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #34b5bf 0%, #210c59 100%);
}
.privacy:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #3615e7 0%, #44a2f6 100%);
}
.backups:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
}
.ssl:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #8d40fb 0%, #5a57fb 100%);
}
.database:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #27b88d 0%, #22dd73 100%);
} 
