.hover {
    overflow: hidden;
    position: relative;
    padding-bottom: 60%;
  }
  
  .hover-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    transition: all 0.4s;
  }
  
  .hover img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
  }
  
  .hover-content {
    position: relative;
    z-index: 99;
  }


.hover-4 img {
    width: 110%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .hover-4 .hover-overlay {
    background: rgba(207, 204, 204, 0.4);
    z-index: 90;
  }
  
  .hover-4-title {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3rem;
    z-index: 99;
  }
  
  .hover-4-description {
    position: absolute;
    top: 2rem;
    left: 2rem;
    text-align: right;
    border-right: 3px solid #fff;
    padding: 0 1rem;
    z-index: 99;
    transform: translateX(-1.5rem);
    opacity: 0;
    transition: all 0.3s;
  }
  
  @media (min-width: 992px) {
    .hover-4-description {
      width: 50%;
    }
  }
  
  .hover-4:hover img {
    width: 100%;
  }
  
  .hover-4:hover::after {
    opacity: 1;
    transform: none;
  }
  
  .hover-4:hover .hover-4-description {
    opacity: 1;
    transform: none;
  }
  
  .hover-4:hover .hover-overlay {
    /* background: rgba(0, 0, 0, 0.8); */
    background: rgba(51, 51, 51, 0.8);;
  }

  /* body {
    min-height: 100vh;
    background-color: "#130b75";
  } */

  .Main-content{
    text-align: justify;
  }
  .Technology_conten{
    text-align: justify;
  }
  .tech_word{
    text-align: justify;
  }

  .centered{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
  }

  .container_item{
    text-align: justify;
    background-color: #4c525c;
    border-radius: 15px;
  }

  .Last_content{
    text-align: justify;
  }








  