
.img_contain {
  transform-origin: 50% 65%;
  transition: all 0.3s ease;
  filter: 3s ease-in;
  filter: brightness(120%);
  margin-left: 5rem;
}
.img_contain:hover img {
  filter: brightness(100%);
  transform: scale(1.6);

  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

.navbar {
  /* background-color: #172470; */
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}
.menu-bars:hover{
  color: aqua;
}

@media  screen and  (max-width:600px){
  .Navbar_Social{
   margin-left: -10px;
  }
}
.nav-menu {
  background-color: #0cb460;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  font-weight: 700;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  /* background-color: #1a83ff; */
  background-color: rgba(240, 10, 129, 0.8);
  height: 50px;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #0cb460;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}
















  