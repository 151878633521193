.row.service_ui{
    text-decoration: none;
}
.Service-title{
  font-size: 40px;
  font-weight: 700;
}
.service_justify{
  text-align: justify;
  font-size: 20px;
}
.software_heading2{
  font-weight: 500;
}
.feature_title:hover{
color:crimson
}
.Service_content{
text-align: justify;
}
.Ser_Cont{
  text-align: justify;
  font-size: 17px;
  font-weight: 400;
}
.Software_Content{
  margin-top: -1rem;
  font-size: 20px;
  padding: 30px;
}
.List-Development:hover{
background-color: #4ef72d;
width: 200px;
height: 35px;
border-radius: 10px;
}

/* ***********************WEB HOSTING CSS START FROM HERE *******************/
.hover-4 img {
    width: 110%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .Hosting_image{
    border-radius: 15px;
  }
  .hover-4 .hover-overlay {
    background: rgba(185, 170, 170, 0.4);
    z-index: 90;
  }
  .hover-4:hover .hover-overlay {
    /* background: rgba(0, 0, 0, 0.8); */
    background: rgba(148, 147, 147, 0.8);;
  }

 


  .text-high{
    font-size: 40px;
    font-weight: 700;
    color:rgba(239, 44, 144, 0.91)
  }

  .my_text {
    text-align: justify;
    font-size: 20px;
    font-weight: 400;
  }

  .Web_Hosting_Content{
    text-align: justify;
    font-size: 20px;
    font-weight: 400;
  }

  .reserach-sub-title{
    font-size: 25px;
    font-weight: 500;
    padding: 15px;
    text-align: justify;
  }
  .research-title{
    color: #1208a1;
    font-size: 30px;
    font-weight: 400;
  }
/* TOOLTIP CSS START FROM HERE */

  .reserach-sub-title {
    position: relative;
    display: inline-block;
    
  }
  
  .reserach-sub-title .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 10;
  }
  
  .reserach-sub-title:hover .tooltiptext {
    visibility: visible;
  }

  .shared_hosting:hover {
    background-color:blueviolet;
    border-radius: 10px;
    color:#fff
  }

  @media screen and (max-width:600px){
    .reserach-sub-title:hover .tooltiptext{
      margin-left:-90px;
      margin-top: 48px;
    }
  }

/* SECOND TEXT TOOLTIP CSS START FROM HERE */
  
.reserach-sub-title .tooltip_Dedicated {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 10;
}

.reserach-sub-title:hover .tooltip_Dedicated {
  visibility: visible;
}

.Dedicated_hosting:hover {
  background-color:blueviolet;
  border-radius: 10px;
  color:#fff
}

@media screen and (max-width:600px){
  .reserach-sub-title:hover .tooltip_Dedicated{
    margin-left:-90px;
    margin-top: 48px;
  }
}
  