/* typewriter css start here */
.typewriter {
  font-family: sans-serif;
  font-weight: 800;
  font-size: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #c2f50c; */
  color: rgba(247, 7, 131, 0.8);
  min-height: 100vh;
  background-image: url(../images/Back3.webp);
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 767px) {
  .typewriter {
    font-size: 40px;
  }
}



 /********************** ENQUIRY BUTTON START FROM HERE *************************/
 .trigger_popup {
  transform: rotate( 270deg );
  position: fixed;
  top: 232px;
  right: -43px;
  z-index: 999;
  cursor: pointer;
  background-color: #660029;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px 16px;
  font-size: 14px;
  color: #fff;
  line-height: 1.33;
 }
 .trigger_popup:hover {
  background-color: #4dff03;
  color:#ff0000
 }
 .border-0 {
  border: 0!important;
}



/* ********************WINDOW POPUP CSS START FROM HERE ******************************************/

#container {
  background: rgba(3, 3, 55, 0.5);
  width: 18.75rem;
  height: 28rem;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

header {
  text-align: center;
  vertical-align: middle;
  line-height: 3rem;
  height: 3rem;
  /* background: rgba(3, 3, 55, 0.7); */
  font-size: 1.4rem;
  color: #d3d3d3;
}

/* fieldset {
  border: 0;
  text-align: center;
} */

.Popup_submit {
  background: rgb(60, 58, 160);
  border: 0;
  display: block;
  width: 70%;
  margin: 0 auto;
  color: white;
  padding: 0.7rem;
  cursor: pointer;
  border-radius: 10px;
}

.Popup_submit:hover {
  background-color: #ff0000;
}
.Popup_Common {
  background: transparent;
  border: 0;
  border-left: 4px solid;
  border-color: #ff0000;
  padding: 10px;
  color: white;
  width: 80%;
}

.phone_class {
  margin-top: 20px;
}
.Popup_Common:focus,
.Popup_Common:focus,
.Popup_Common:focus {
  outline: 0;
  background: rgba(235, 30, 54, 0.3);
  border-radius: 1.2rem;
  border-color: transparent;
}

::placeholder {
  color: #d3d3d3;
}

/*Media queries */

@media all and (min-width: 481px) and (max-width: 568px) {
  #container {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}
@media all and (min-width: 569px) and (max-width: 768px) {
  #container {
    margin-top: 5%;
    margin-bottom: 5%;
  }
}

.modal-content {
  border: #ff0000;
}






/* .box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #06d85f;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.button:hover {
  background: #06d85f;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06d85f;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
} */





/* ***********SOCIAL BUTTON START FROM HERE *****************/

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
@import url(https://weloveiconfonts.com/api/?family=entypo);

[class^="entypo-"]:before {
  font-family: "entypo", sans-serif;
}
/* body {
    background: #e6e6e6;
  } */
#social-sidebar {
  left: -33px;
  position: fixed;
  top: 30%;
  z-index: 999;
}

#social-sidebar li:first-child a {
  border-top-right-radius: 5px;
}
#social-sidebar li:last-child a {
  border-bottom-right-radius: 5px;
}

#social-sidebar a {
  background: #1e0694;
  color: #fff;
  text-decoration: none;
  display: block;
  height: 50px;
  width: 50px;
  font-size: 24px;
  line-height: 50px;
  position: relative;
  text-align: center;
  cursor: pointer;
}
#social-sidebar a:hover span {
  left: 120%;
  opacity: 1;
}
#social-sidebar a span {
  font: 12px "Open Sans", sans-serif;
  text-transform: uppercase;
  border-radius: 3px;
  line-height: 24px;
  left: -100%;
  margin-top: -16px;
  opacity: 0;
  padding: 4px 8px;
  position: absolute;
  transition: opacity 0.3s, left 0.4s;
  top: 50%;
  z-index: -1;
}

#social-sidebar a span:before {
  content: "";
  display: block;
  height: 8px;
  width: 8px;
  left: -4px;
  margin-top: -4px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
}

#social-sidebar a[class*="twitter"]:hover,
#social-sidebar a[class*="twitter"] span,
#social-sidebar a[class*="twitter"] span:before {
  background: #00aced;
}

#social-sidebar a[class*="facebook"]:hover,
#social-sidebar a[class*="facebook"] span,
#social-sidebar a[class*="facebook"] span:before {
  background: #3b5998;
}

#social-sidebar a[class*="gplus"]:hover,
#social-sidebar a[class*="gplus"] span,
#social-sidebar a[class*="gplus"] span:before {
  background: #e34429;
}

#social-sidebar a[class*="dribbble"]:hover,
#social-sidebar a[class*="dribbble"] span,
#social-sidebar a[class*="dribbble"] span:before {
  background: #ea4c89;
}

#social-sidebar a[class*="dropbox"]:hover,
#social-sidebar a[class*="dropbox"] span,
#social-sidebar a[class*="dropbox"] span:before {
  background: #8dc5f2;
}

#social-sidebar a[class*="github"]:hover,
#social-sidebar a[class*="github"] span,
#social-sidebar a[class*="github"] span:before {
  background: #9c7a5b;
}

#social-sidebar a[class*="evernote"]:hover,
#social-sidebar a[class*="evernote"] span,
#social-sidebar a[class*="evernote"] span:before {
  background: #6bb130;
}

/********************** HOVER IMAGE CARD CSS START FROM HERE****************************** */
.card-wrapper {
  margin-bottom: 30px;
}
.card-image .card .card-img-wrapper {
  height: 100%;
}
.card-image .card .card-body {
  display: none;
}
.card-image-title-description .card .card-img-wrapper {
  max-height: 160px;
}
.card-image-title-description .card {
  position: relative;
  min-height: 300px;
}
.card-image-title-description .card .card-body {
  height: auto;
  position: relative;
  top: 0;
  margin-bottom: -70px;
}
.card-image-title-description .card:hover .card-body {
  top: -70px;
}
.card-image-title-description .card .card-body .card-title {
  margin-bottom: 0.75rem;
}
.card {
  display: inline-block;
  position: relative;
  overflow: hidden;
  min-height: 400px;
  height: 100%;
}
.card:hover {
  box-shadow: 8px 12px 31px -10px #ab98ab;
}
.card-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  overflow: hidden;
}
.card-img-wrapper img {
  transition: 1.5s ease;
}
.card:hover .card-img-wrapper img {
  transform: scale(1.15);
}
.card-body .card-title {
  margin-bottom: calc(50% + 20px);
  transition: 1.5s ease;
}
.card:hover .card-body .card-title {
  margin-bottom: 0.75rem;
}

.card-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background-color: #fff;
  transition: 1.5s ease;
}
.card-content {
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
  transition: 1.5s ease;
}
.card:hover .card-body {
  height: 80%;
}
.card:hover .card-content {
  bottom: 0;
}
body {
  margin: 0;
  background-image: linear-gradient(to right, #ece9e6, #ffffff);
}

.text_slide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/********************************** our Testimonials *****************************/
.client {
  width: 100%;
  height: 100%;
  /* background-color: #2945df; */
  background-color: #6d7aed;
}
.t-card {
  padding: 1.8125rem 1.125rem;
  background-color: rgba(15, 3, 106, 0.73);
  border-radius: 1.25rem;
  color: white;
  height: auto;
}

.arrow-down {
  width: 0;
  height: 10;
  border-left: 1.5625rem solid transparent;
  border-right: 1.5625rem solid transparent;
  border-top: 1.25rem solid rgba(15, 3, 106, 0.73);
}

.testimonial_content {
  text-align: justify;
}

@media (max-width: 767px) {
  .gap-top {
    margin-top: 50px;
  }
}

.succes-text {
  text-align: justify;
  color: #141416;
  font-size: 20px;
  font-weight: 600;
}

.our-approch-title {
  font-weight: 650;
  font-size: 50px;
}

/* **********************OUR APPROACH CSS START HERE ************************** */

.banner-wrapp {
  background-image: url(../images/ourbaner.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 0;
  background-position: center;
}
.banner-title {
  font-size: 50px;
  font-weight: 600;
}

.approach-text {
  text-align: justify;
  font-size: 20px;
  font-weight: 600;
}

/* ========================================= * 
	
   OUR EXPERTISE  CSS START FROM HERE 
 * ========================================= */

/* *
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: consolas;
}

body
{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #1d061a
} */

.Expertise {
  font-size: 50px;
  font-weight: 700;
  background-color: #1d061a;
}
.contempt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;
  min-height: 100vh;
  background: #1d061a;
}

.contempt .box {
  position: relative;
  width: 320px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
}

.contempt .box::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 50px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
}

.contempt .box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  transform: skewX(15deg);
  transition: 0.5s;
  filter: blur(30px);
}

.contempt .box:hover:before,
.contempt .box:hover:after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);
}

.contempt .box:nth-child(1):before,
.contempt .box:nth-child(1):after {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
}

.contempt .box:nth-child(2):before,
.contempt .box:nth-child(2):after {
  background: linear-gradient(315deg, #03a9f4, #ff0058);
}

.contempt .box:nth-child(3):before,
.contempt .box:nth-child(3):after {
  background: linear-gradient(315deg, #4dff03, #00d0ff);
}

.contempt .box span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.contempt .box span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.1s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.contempt .box:hover span::before {
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

.contempt .box span::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  transition: 0.5s;
  animation: animate 2s ease-in-out infinite;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  animation-delay: -1s;
}

.contempt .box:hover span:after {
  bottom: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  opacity: 1;
}

@keyframes animate {
  0%,
  100% {
    transform: translateY(10px);
  }

  50% {
    transform: translate(-10px);
  }
}

.contempt .box .content {
  position: relative;
  left: 0;
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  transform: 0.5s;
  color: #fff;
}

.contempt .box:hover .content {
  left: -25px;
  padding: 60px 40px;
}

.contempt .box .content h2 {
  font-size: 2em;
  color: #fff;
  margin-bottom: 10px;
}

.contempt .box .content p {
  font-size: 1.1em;
  margin-bottom: 10px;
  line-height: 1.4em;
}

.contempt .box .content a {
  display: inline-block;
  font-size: 1.1em;
  color: #111;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
  margin-top: 5px;
}

.contempt .box .content a:hover {
  background: #ffcf4d;
  border: 1px solid rgba(255, 0, 88, 0.4);
  box-shadow: 0 1px 15px rgba(1, 1, 1, 0.2);
}
